#wholesale-popover {
    .ant-card-small > .ant-card-body {
        padding: 12px !important;
    }
    
    .ant-statistic-content {
        font-size: 16px !important;
    }
    
    .ant-divider-horizontal {
        margin: 10px 0 !important;
    }
    
    .ant-popover-title {
        text-align: center !important;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 !important;
    }
} 