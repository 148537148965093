#login-section {
    justify-content: center;
    align-items: center;
    .login-form {
        max-width: 300px;
        margin: auto;
    }
    .login-form-forgot {
        float: right;
    }
    .ant-col-rtl .login-form-forgot {
        float: left;
    }
    .login-form-button {
        width: 100%;
        margin-bottom: 1em;
    }
    .error-alert {
        max-width: 300px;
        margin: 1em auto;
    }
}
